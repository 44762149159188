<template>
  <v-app-bar
    absolute
    :color="background"
    height="auto"
    class="py-4 px-2 toolbar-content-padding-y-none"
    flat
  >
    <v-container>
     
      <v-row dense>
        <v-col class="text-center">
          <p class="font-weight-light text-white text-sm">
            RenderGlitch {{ packageInfo.version }} Backend System
          </p></v-col
        >
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
const packageInfo = require("../../../package.json");

export default {
  name: "unauth-app-bar-auth",

  props: {
    background: String,
  },
  data() {
    return {
      packageInfo: packageInfo,
    };
  },
};
</script>
