import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import jwt from "jsonwebtoken";
import config from "@/config";
import localStorageService from "@/services/localStorageService";
import rightsServices from "@/services/rights";
import avatars from "@/services/avatars";
import usersManager from "@/apis/users";
import i18n from "@/plugins/i18n";
import staticData from "@/apis/static";

const getNavigatorLanguage = () =>
  navigator.languages && navigator.languages.length
    ? navigator.languages[0]
    : navigator.userLanguage ||
      navigator.language ||
      navigator.browserLanguage ||
      "en";

Vue.use(Vuex);
// Reload user information from the cached authToken
let localAuthUser = null;
let localAccessToken = localStorageService.getAccessToken();
let localAvatar = localStorageService.getAvatar();
let notificationObject = localStorageService.getNotifications();
let localLocale = localStorageService.getLocale();
let localNotifications = [];
try {
  localNotifications = notificationObject ? JSON.parse(notificationObject) : {};
} catch (err) {
  localNotifications = [];
}

if (!localLocale) {
  localLocale = getNavigatorLanguage()
    ? getNavigatorLanguage().substr(0, 2)
    : "it";
  i18n.locale = localLocale;
} else {
  i18n.locale = localLocale;
}

if (localAccessToken) {
  let decodedToken = jwt.decode(localAccessToken);
  if (decodedToken) {
    localAuthUser = decodedToken.user;
    localAuthUser.avatar = localAvatar;
    localAuthUser.notifications = localNotifications;
    localAuthUser.locale = localLocale;
  }

  i18n.locale = localLocale;
}

let localData = localStorageService.getLocalData();

if (!localData) localData = {};

if (!localAuthUser || !localData) {
  localAuthUser = {
    gravatar: null,
    first_name: "",
    last_name: "",
    qualification: "",
    mobile_phone: "",
    contact_phone: "",
    birth_date: null,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    gender: "F",
    id: 0,
    rights: [],
    category: null,
    role: null,
    avatar: localAvatar,
    email: "",
    notifications: [],
    idlekick: 0,
    locale: getNavigatorLanguage() ? getNavigatorLanguage().substr(0, 2) : "it",
  };
  i18n.locale = localAuthUser.locale;
}

staticData.update();

let spoolerNumOfCopies = localStorageService.getSpoolerNumOfCopies();
let context = localStorageService.getContext();
let identity = localStorageService.getIdentity();

export default new Vuex.Store({
  state: {
    spooler_num_of_copies: spoolerNumOfCopies,
    global_overlay: null,
    global_overlay_text: null,
    global_overlay_progress: null,
    drawer: null,
    loggingIn: false,
    user: {
      last_login: localAuthUser.last_login,
      last_login_ip: localAuthUser.last_login_ip,
      first_name: localAuthUser.first_name,
      last_name: localAuthUser.last_name,
      qualification: localAuthUser.qualification,
      birth_date: localAuthUser.birth_date,
      timezone: localAuthUser.timezone,
      id: localAuthUser.id,
      rights: localAuthUser.rights,
      email: localAuthUser.email,
      avatar: localAvatar,
      gravatar: localAuthUser.gravatar,
      gender: localAuthUser.gender,
      notifications: localAuthUser.notifications,
      locale: localAuthUser.locale,
      idlekick: localAuthUser.idlekick,
      category: localAuthUser.category,
      default_location: localAuthUser.default_location,
      role: localAuthUser.role,
    },

    frontend: localData.frontend,
    versions: localData.versions,
    identity_data: localData.identity_data,
    spooler_online: false,
    context: context,
    identity: identity,

    hasRight: function (right) {
      return rightsServices.hasRight(this.user.rights, right);
    },

    isAdmin: function () {
      return rightsServices.isAdmin(this.user.rights);
    },

    isSuperAdmin: function () {
      return rightsServices.isSuperAdmin(this.user.rights);
    },

    storeContextAndIdentity: function (context, identity) {
      this.context = context;
      this.identity = identity;
      localStorageService.setIdentity(identity);
      localStorageService.setContext(context);
    },
  },

  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    },

    loginStart: (state) => {
      state.loggingIn = true;
    },

    loginCompleted: (state, payload) => {
      if (payload.access_token && payload.refresh_token && payload.u) {
        localStorageService.setToken({
          access_token: payload.access_token,
          refresh_token: payload.refresh_token,
        });
        localStorageService.setAvatar(payload.avatar);

        state.user = payload.u;
        state.user.avatar = payload.avatar;

        if (!state.user.locale)
          state.user.locale = getNavigatorLanguage()
            ? getNavigatorLanguage().substr(0, 2)
            : "it";
        localStorageService.setLocale(state.user.locale);
        staticData.update();
      } else {
        state.user = {};
        localStorageService.clearToken();
      }
      state.loggingIn = false;
    },

    logoutCompleted: (state) => {
      state.loggingIn = false;
      state.user = { locale: state.user.locale }; // We keep only the locale
      localStorageService.clearToken();
    },

    staticDataCompleted: (state, payload) => {
      state.frontend = payload.frontend;
      state.identity_data = payload.identity_data;
      localStorageService.setLocalData(payload);
    },
  },

  actions: {
    impersonate({ commit }, payload) {
      return new Promise((resolve /*, reject*/) => {
        commit("loginCompleted", {
          access_token: payload.authToken,
          refresh_token: payload.refreshToken,
          u: payload.user,
          avatar: null,
          notifications: [],
        });

        resolve();
      });
    },

    login({ commit }, payload) {
      return new Promise((resolve, reject) => {
        commit("loginStart");
        axios({
          url: config.apiEndPoint + "/auth/login",
          data: payload,
          method: "POST",
        })
          .then((resp) => {
            if (resp.data.responseError) {
              commit("loginCompleted", { error: resp.data.responseError });
              localStorageService.clearToken();
              reject(resp.data.responseError);
            } else {
              let accessToken = resp.data.responseData.authToken;
              let refreshToken = resp.data.responseData.refreshToken;
              let responseUser = resp.data.responseData.user;
              let notifications = resp.data.responseData.notifications;
              if (!accessToken || !payload || !refreshToken) {
                commit("loginCompleted", {});
                localStorageService.clearToken();
                if (resp.data.responseError) reject(resp.data.responseError);
                else reject("Invalid response from server");
              } else {
                if (responseUser.gravatar == true) {
                  avatars
                    .fetchGravatar(responseUser.email)
                    .then((result) => {
                      let responseAvatar = result;
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: responseAvatar,
                        notifications: notifications,
                      });
                      resolve(resp.data.responseData);
                    })
                    .catch((err) => {
                      console.log(err);
                      commit("loginCompleted", {
                        access_token: accessToken,
                        refresh_token: refreshToken,
                        u: responseUser,
                        avatar: null,
                        notifications: notifications,
                      });
                      resolve(resp.data.responseData);
                    });
                } else if (responseUser.gravatar == false) {
                  // Fetch user profile picture
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  usersManager
                    .fetchAvatar(responseUser.id)
                    .then((result) => {
                      this.state.user.avatar = result.avatar;
                      localStorageService.setAvatar(this.state.user.avatar);
                      resolve(resp.data.responseData);
                    })
                    .catch((err) => {
                      console.log(err);
                      resolve(resp.data.responseData);
                    });
                } else {
                  commit("loginCompleted", {
                    access_token: accessToken,
                    refresh_token: refreshToken,
                    u: responseUser,
                    avatar: null,
                    notifications: notifications,
                  });
                  resolve(resp.data.responseData);
                }
              }
            }
          })
          .catch((err) => {
            commit("loginCompleted", { error: err });
            localStorageService.clearToken();
            reject(err);
          });
      });
    },

    logout({ commit }) {
      return new Promise((resolve, reject) => {
        axios({
          url: config.apiEndPoint + "/auth/logout",
          data: {},
          method: "POST",
        })
          .then(function () {
            commit("logoutCompleted");
            resolve();
          })
          .catch(function (error) {
            commit("logoutCompleted");
            reject(error);
          });
      });
    },

    unauthorized({ commit }) {
      return new Promise((resolve) => {
        commit("logoutCompleted");
        resolve();
      });
    },

    fetchStaticData({ commit }) {
      return new Promise((resolve, reject) => {
        axios({ url: config.apiEndPoint + "/db/static", method: "POST" })
          .then((resp) => {
            commit("staticDataCompleted", resp.data.responseData);
            resolve();
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },

    currentLocationChanged({ commit }, payload) {
      commit("locationChanged", payload);
    },
  },
});
