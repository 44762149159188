import axios from "axios";
import config from "@/config";
import store from "@/store";
import localStorageService from "@/services/localStorageService";

let users = {
  forceTokenRefresh: () => {
    return new Promise((resolve, reject) => {
      const refreshToken = localStorageService.getRefreshToken();
      axios
        .post(config.apiEndPoint + "/auth/refresh", {
          refresh_token: refreshToken,
        })
        .then((res) => {
          if (res.status === 200) {
            if (res.data.responseData) {
              if (
                res.data.responseData.authToken &&
                res.data.responseData.refreshToken
              ) {
                localStorageService.setToken({
                  access_token: res.data.responseData.authToken,
                  refresh_token: res.data.responseData.refreshToken,
                });
              }
              if (res.data.responseData.notifications) {
                store.state.user.notifications =
                  res.data.responseData.notifications;
                localStorageService.setNotifications(
                  JSON.stringify(store.state.user.notifications)
                );
              }
              resolve();
            }
          } else {
            reject();
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchGroups: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.groups,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addGroup: (group) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/add",
        data: { group: group },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData.group);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateGroup: (group) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/update",
        data: { group: group },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeGroup: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/groups/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchAvatar: (id, customer) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/avatar",
        data: { id: id, customer: customer },
        method: "POST",
      })
        .then((resp) => {
          resolve({ avatar: resp.data.responseData });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUsers: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.users,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUsersNames: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/names",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.users });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchSamplePickers: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/samplePickersNames",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ users: resp.data.responseData.users });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchReferenceType: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/referenceTypes",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ types: resp.data.responseData.types });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUsersNamesAndMails: (filter) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/namesAndMails",
        data: { filter: filter },
        method: "POST",
      })
        .then((resp) => {
          resolve({ items: resp.data.responseData.users });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  banUser: (user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/ban",
        data: { id: user.id, state: !user.banned },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addUser: (user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/add",
        data: { user: user },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateUser: (user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/update",
        data: { user: user },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchUser: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/fetch",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateProfile: (user) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateProfile",
        data: { user: user },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  checkMail: (mail, id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/checkMail",
        data: { email: mail, id: id },
        method: "POST",
      })
        .then((count) => {
          resolve(count);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  uploadAvatar: (content) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/updateAvatar",
        data: { content: content },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeUser: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/delete",
        data: { id: id },
        method: "POST",
      })
        .then(() => {
          resolve();
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  impersonateUser: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/auth/impersonate",
        data: { id: id },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchProfileExtraInfo: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/extraInfo",
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchTimeZones: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/timeZones",
        method: "POST",
      })
        .then((resp) => {
          resolve(resp.data.responseData);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  changePassword: (password, newPassword) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/changePassword",
        data: { password: password, newPassword: newPassword },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  recoveryAccount: (email) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/recoveryAccount",
        data: { email: email },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  resetPassword: (password, token) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/resetPassword",
        data: { password: password, token: token },
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  keepAlive: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/keepAlive",
        data: {},
        method: "POST",
      })
        .then((state) => {
          resolve(state);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchCategories: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/categories/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.categories,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  addCategory: (category) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/category/add",
        data: { category: category },
        method: "POST",
      })
        .then((resp) => {
          resolve({ lastInsertId: resp.data.responseData.lastInsertId });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  updateCategory: (category) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/category/update",
        data: { category: category },
        method: "POST",
      })
        .then((resp) => {
          resolve(resp);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  removeCategory: (id) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/category/delete",
        data: { id: id },
        method: "POST",
      })
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  },

  fetchRoles: () => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/roles",
        data: {},
        method: "POST",
      })
        .then((resp) => {
          resolve({ roles: resp.data.responseData.roles });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
  fetchDoctors: (ctx) => {
    return new Promise((resolve, reject) => {
      axios({
        url: config.apiEndPoint + "/users/doctors/list",
        data: { ctx: ctx },
        method: "POST",
      })
        .then((resp) => {
          resolve({
            items: resp.data.responseData.doctors,
            totalCount: resp.data.responseData.count,
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  },
};

export default users;
