import Vue from "vue";
import store from "@/store";
import moment from "moment";

function padDate(value) {
  value = value.toString();
  if (value.length < 2) value = "0" + value;
  return value;
}

Vue.filter("toCurrency", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("toCurrencySymbol", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (typeof value !== "number") {
    return value;
  }

  var formatter = new Intl.NumberFormat(locale, {
    style: "currency",
    currency: "EUR",
    minimumFractionDigits: 2,
  });
  return formatter.format(value);
});

Vue.filter("toDatePickerDate", function (value) {
  if (!value) return "";
  if (typeof value == "string") {
    try {
      value = new Date(Date.parse(value));
      return (
        value.getYear() +
        1900 +
        "-" +
        padDate(value.getMonth() + 1) +
        "-" +
        padDate(value.getDate())
      );
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("toLocaleDate", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (!value) return "";
  if (typeof value == "string") {
    try {
      value = Date.parse(value);
      var formatter = new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });
      return formatter.format(value);
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("UTCToLocalDateTime", function (value) {
  try {
    let date = moment.utc(value, "YYYY-MM-DD HH:mm:ss");
    let localDate = date.local().format("DD-MM-YYYY HH:mm");
    return localDate;
  } catch (err) {
    return null;
  }
});


Vue.filter("unixToLocaleDateTime", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (!value) return "";
  if (typeof value == "number") {
    try {
      value = new Date(value * 1000);
      var formatter = new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return formatter.format(value);
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("unixToLocaleDateTimeNoSeconds", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;
  if (!value) return "";
  if (typeof value == "number") {
    try {
      value = new Date(value * 1000);
      var formatter = new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return formatter.format(value);
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("toLocaleDateTime", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (!value) return "";
  if (typeof value == "string") {
    try {
      value = Date.parse(value);
      var formatter = new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });
      return formatter.format(value);
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("toLocaleDateTimeNoSeconds", function (value) {
  let locale = "it-IT";
  if (store.state.user.id) locale = store.state.user.locale;

  if (!value) return "";
  if (typeof value == "string") {
    try {
      value = Date.parse(value);
      var formatter = new Intl.DateTimeFormat(locale, {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
      return formatter.format(value);
    } catch (err) {
      return "";
    }
  }
});

Vue.filter("UTCToLocalDate", function (value) {
  try {
    let date = moment.utc(value, "YYYY-MM-DD HH:mm:ss");
    let localDate = date.local().format("DD-MM-YYYY");
    return localDate;
  } catch (err) {
    return null;
  }
});

Vue.filter("UTCToLocalTimeNoSec", function (value) {
  try {
    let date = moment.utc(value, "YYYY-MM-DD HH:mm:ss");
    let localDate = date.local().format("DD-MM-YYYY HH:mm");
    return localDate;
  } catch (err) {
    return null;
  }
});

Vue.filter("UTCToLocalTime", function (value) {
  try {
    let date = moment.utc(value, "YYYY-MM-DD HH:mm:ss");
    let localDate = date.local().format("DD-MM-YYYY HH:mm:ss");
    return localDate;
  } catch (err) {
    return null;
  }
});
