import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import UnauthenticatedLayout from "@/views/layout/UnauthenticatedLayout";
import AuthenticatedLayout from "@/views/layout/AuthenticatedLayout";
import Login from "@/views/framework/Login";

const UsersManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/UsersManager.vue"
  );
const GroupsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/GroupsManager.vue"
  );
const ApplicationsManager = () =>
  import(
    /* webpackChunkName: "admin-bundle" */ "@/views/framework/ApplicationsManager.vue"
  );

const Logs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Logs.vue");

const Dashboard = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/Dashboard.vue"
  );

const DashboardJobs = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/DashboardJobs.vue"
  );

const Recovery = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/framework/Recovery.vue"
  );

const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Reset.vue");
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Profile.vue");

const Identity = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/framework/Identity.vue"
  );

const DataCenters = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/operations/DataCenters.vue");
const Jobs = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/operations/Jobs.vue");
const Invoices = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/operations/Invoices.vue"
  );
const JobDetail = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/JobDetail.vue"
  );
const FileManager = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/FileManager.vue"
  );

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/auth",
      component: UnauthenticatedLayout,
      children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
        },
        {
          path: "/recovery",
          name: "Recovery",
          component: Recovery,
        },
        {
          path: "/reset",
          name: "Reset",
          component: Reset,
        },
      ],
    },

    {
      path: "/gbl",
      component: AuthenticatedLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/dashboard-jobs",
          name: "dashboard-jobs",
          component: DashboardJobs,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/jobs",
          name: "jobs",
          component: Jobs,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/datacenters",
          name: "datacenters",
          component: DataCenters,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/job/:id",
          name: "job",
          component: JobDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/invoices",
          name: "invoices",
          component: Invoices,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/filemanager",
          name: "filemanager",
          component: FileManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/permissions-users",
          name: "users",
          component: UsersManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/permissions-groups",
          name: "groups",
          component: GroupsManager,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/permissions-applications",
          name: "applications",
          component: ApplicationsManager,
          meta: {
            requiresAuth: true,
            requiresSuperAdmin: true,
          },
        },

        {
          path: "/profile",
          name: "profile",
          component: Profile,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/identity",
          name: "identity",
          component: Identity,

          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/logs",
          name: "logs",
          component: Logs,

          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    next("/login");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
