<template>
  <v-app>
    <v-main class="auth-pages">
      <v-container v-if="!context_loaded" fluid fill-height>
        <v-layout class="align-center justify-center">
          <v-progress-circular
            :indeterminate="true"
            :rotate="20"
            :size="128"
            :width="10"
            color="#3A416F"
          ></v-progress-circular>
        </v-layout>
      </v-container>

      <div v-if="context_loaded">
        <div
          class="header-auth position-relative ma-4 pb-8 pt-8 border-radius-xl"
          :style="`  background-image:  url(${require('@/../../shared/app/assets/img/login_background.png')}; background-size: cover; background-position:center; `"
        >
          <v-container>
            <v-row class="pt-8 d-flex mt-10">
              <v-col class="text-center">
                <v-img
                  class="mx-auto"
                  max-width="256"
                  max-height="256"
                  :src="require('@/../../shared/app/assets/logo.png')"
              /></v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="6" class="mx-auto py-0 position-relative">
                <h3
                  class="text-h3 font-weight-bold text-white text-center mb-2"
                >
                  {{ headerTitle() }}
                </h3>

                <p
                  class="text-white font-size-root text-center text-h6 font-weight-light mb-12"
                >
                  {{ paragraphs }}
                </p>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <unauth-app-bar background="transparent" has-bg linkColor="white">
        </unauth-app-bar>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <v-container class="mt-n16 pb-0">
            <router-view></router-view>
            <unauth-content-footer
              auth
              v-if="!$route.meta.hideFooter"
            ></unauth-content-footer>
          </v-container>
        </fade-transition>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import UnauthAppBar from "@/components/mockup/UnauthAppBar";
import { FadeTransition } from "vue2-transitions";
import UnauthContentFooter from "@/components/mockup/UnauthFooter.vue";

import context from "@/apis/context";

export default {
  name: "page-layout",
  components: {
    UnauthAppBar,
    FadeTransition,
    UnauthContentFooter,
  },
  data() {
    return {
      paragraphs: "",
      tab: null,
      context_loaded: false,
    };
  },

  mounted() {
    this.context_loaded = false;
    context
      .fetchContext()
      .then((res) => {
        this.context_loaded = true;
        this.$store.state.storeContextAndIdentity(res.context, res.identity);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  computed: {
    logoImage() {
      return "data:image/png;base64," + this.$store.state.identity.logo_file;
    },
  },

  methods: {
    headerTitle() {
      switch (this.$route.name) {
        case "Login":
          this.paragraphs = this.$t("renderglitch-system-reserved", {
            company: this.$store.state.identity
              ? this.$store.state.identity.name
              : "",
          });
          break;
        default:
          break;
      }
    },
  },
};
</script>
