<template>
  <v-app-bar
    :color="background"
    height="auto"
    class="elevation-3 mt-5 px-0 mx-6 border-radius-xl toolbar-content-padding-y-none"
    :class="
      navbarFixed
        ? 'position-sticky blur shadow-blur top-1 z-index-sticky py-2'
        : ''
    "
    flat
  >
    <v-row class="py-1">
      <v-col cols="12" sm="8" class="d-flex align-center">
        <div
          class="drawer-toggler mr-2 cursor-pointer"
          :class="{ active: togglerActive }"
          @click="minifyDrawer"
          v-if="!$vuetify.breakpoint.mobile"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
            <i class="drawer-toggler-line bg-body"></i>
          </div>
        </div>

        <div>
          <Avatar
            ref="avatar"
            :editable="false"
            :email="$store.state.user.email"
          />

          <span style="display: inline-block" class="ml-2 header-welcome">
            <v-row>
              <v-col>
                {{
                  $store.state.user.gender == "M"
                    ? $t("renderglitch-welcome-male", {
                        name: $store.state.user.first_name,
                      })
                    : $t("renderglitch-welcome-female", {
                        name: $store.state.user.first_name,
                      })
                }}
                <p class="mb-0 font-weight-bold text-body text-sm">
                  {{
                    $t("header-welcome", {
                      notifications:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.notifications
                          ? $store.state.user.notifications.length
                          : 0,
                      last_login:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login
                          ? $options.filters.UTCToLocalTime(
                              $store.state.user.last_login
                            )
                          : 0,
                      last_login_ip:
                        $store.state &&
                        $store.state.user &&
                        $store.state.user.last_login_ip
                          ? $store.state.user.last_login_ip
                          : "",
                    })
                  }}
                </p>
              </v-col>
            </v-row>
          </span>
        </div>
      </v-col>

      <v-col cols="12" sm="4" class="d-flex align-center justify-end">
        <div
          class="v-navigation-drawer-brand pa-5 d-flex align-center"
          v-if="!$vuetify.breakpoint.mobile && mini"
        >
          <v-img
            src="@/../../shared/app/assets/logo_boxed.png"
            class="navbar-brand-img ms-3"
            width="35"
          />
          <span class="ms-2 font-weight-bold text-sm"
            >RENDERGLITCH {{ packageInfo.version }}</span
          >
        </div>

        <v-btn
          elevation="0"
          height="43"
          class="font-weight-600 text-capitalize drawer-toggler py-3 px-0 rounded-sm"
          :class="{
            'btn-dark-toggler-hover': !hasBg,
            'btn-toggler-hover': hasBg,
            active: togglerActive,
          }"
          v-if="$vuetify.breakpoint.mobile"
          color="transparent"
          @click="drawerClose"
        >
          <div class="drawer-toggler-inner">
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
            <i class="drawer-toggler-line text-body"></i>
          </div>
        </v-btn>

        <v-menu
          bottom
          left
          min-width="200"
          offset-y
          origin="top right"
          transition="scale-transition"
        >
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              icon
              :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
              class="text-body ml-2"
              :color="linkColor"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="24">mdi-account</v-icon>
            </v-btn>
          </template>

          <v-list :tile="false" flat nav>
            <template v-for="(p, i) in profile">
              <v-divider
                v-if="p.divider"
                :key="`divider-${i}`"
                class="mb-2 mt-2"
              />
              <v-list-item v-else :key="`item-${i}`">
                <v-list-item-title
                  class="pa-4 list-item-hover-active"
                  v-text="p.title"
                  @click="handleClick(p)"
                />
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
      </v-col>
    </v-row>
  </v-app-bar>
</template>
<script>
const packageInfo = require("../../../package.json");

import Avatar from "./Avatar";
import AvatarServices from "@/services/avatars";
import identityManager from "@/apis/identity";
import localStorageService from "@/services/localStorageService";
import { EventBus } from "@/plugins/bus";

export default {
  name: "app-bar",
  props: {
    background: String,
    linkColor: String,
    toggleActive: Boolean,
  },
  data() {
    return {
      mini: false,
      packageInfo: packageInfo,
      navbarFixed: true,
      hasBg: true,
      drawer: false,
      togglerActive: false,
      profile: [],
      incCounterRecomputer: 0,
    };
  },

  components: {
    Avatar,
  },

  mounted() {
    this.profile = [{ title: this.$t("header-profile"), url: "/profile" }];

    this.profile = [
      ...this.profile,
      { divider: true },
      { title: this.$t("header-logout"), func: this.logout },
    ];
    EventBus.$on("avatar-changed", this.avatarUpdater);
  },

  methods: {
    avatarUpdater() {
      if (this.$refs.avatar) this.$refs.avatar.loadAvatar();
    },

    drawerClose() {
      this.togglerActive = !this.togglerActive;
      this.$emit("drawer-toggle", true);
    },

    minifyDrawer() {
      this.togglerActive = !this.togglerActive;
      this.mini = !this.mini;
      const body = document.getElementsByTagName("body")[0];

      if (body.classList.contains("drawer-mini")) {
        body.classList.remove("drawer-mini");
      } else {
        body.classList.add("drawer-mini");
      }
    },

    buildGravatar: function (notification) {
      return (
        "<img style='width:48px; border:1px solid black; border-radius: 50%;' src='data:image/jpeg;base64," +
        notification.gravatar +
        "'>"
      );
    },

    checkGravatar: function (notification) {
      return new Promise((resolve, reject) => {
        if (!notification.gravatarFetched) {
          notification.gravatarFetched = true;
          AvatarServices.fetchAvatar(notification.avatar_id)
            .then((result) => {
              notification.gravatar = result.avatar;
              resolve();
            })
            .catch((err) => {
              reject(err);
            });
        } else {
          resolve();
        }
      });
    },

    handleClick: function (item) {
      if (item.func) item.func();
      else if (item.url) {
        if (item.url != this.$router.currentRoute.path)
          this.$router.push(item.url);
      }
    },

    logout: function () {
      this.$store
        .dispatch("logout")
        .then(() => {
          this.$router.push("/login").catch(() => {});
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/login").catch(() => {});
        });
    },
  },

  computed: {
    notifications: function () {
      this.incCounterRecomputer;
      if (
        this.$store.state.user.notifications &&
        this.$store.state.user.notifications.length
      ) {
        let not = [];
        for (let n = 0; n < this.$store.state.user.notifications.length; n++) {
          let ntf = this.$store.state.user.notifications[n];
          not.push({ ...ntf, title: ntf.name, valid: true, ref: ntf });
        }
        return not;
      } else {
        return [{ title: this.$t("header-nonotifications"), valid: false }];
      }
    },

    notificationBadgeNumber: function () {
      let n = 0;
      for (let x = 0; x < this.notifications.length; x++) {
        if (this.notifications[x].valid && !this.notifications[x].ref.acked)
          n++;
      }
      return n;
    },
  },
  watch: {
    toggleActive(val) {
      this.togglerActive = val;
    },
  },
};
</script>
